/*
 * This file is part of Testfish Runner.
 * (c) Basis Europe <eu@basis.com>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { collectCandidateInfo } from '../../modules/testrunner'
import Spinner from '../spinner'
import { withTranslation } from 'react-i18next'
import { Formik, Field } from 'formik'


class CandidateForm extends React.Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(values, { setSubmitting, resetForm }) {
    this.props.collectCandidateInfo(this.props.trun, values)
  }

  render() {
    const { t, loading, collected } = this.props
    return (
      <React.Fragment>
        <div className={collected ? 'hidden' : ''}>
          <div className="form">
            {loading && <Spinner opacity/>} 
            <fieldset>
              <legend>{t('finished.candidateForm.title')}</legend>
              <p>
                  {t('finished.candidateForm.message')}
              </p>
              <Formik
                initialValues={{
                  firstname: '',
                  name: '',
                  email: '',
                  notes: ''
                }}
                onSubmit={this.onSubmit}
                // validate={this.onValidate}
                validateOnChange
                validateOnBlur>
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid = false,
                  touched,
                  errors,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Field name="firstname">
                      {({ field, form: { touched, errors }, meta }) => (
                        <label>
                          {t('finished.candidateForm.firstName.title')}
                          <input
                            {...field}
                            type="text"
                            name="firstname"
                            pattern="([a-zA-ZäöüÄÖÜß\s]){2,20}"
                            placeholder={t(
                              'finished.candidateForm.firstName.placeholder'
                            )}
                            required
                          />
                          {/* {meta.touched && meta.error && (
                    <div className="error">{meta.error}</div>
                  )} */}
                        </label>
                      )}
                    </Field>

                    <Field name="name">
                      {({ field, form: { touched, errors }, meta }) => (
                        <label>
                          {t('finished.candidateForm.name.title')}
                          <input
                            {...field}
                            type="text"
                            name="name"
                            pattern="([a-zA-ZäöüÄÖÜß\s]){2,20}"
                            placeholder={t(
                              'finished.candidateForm.name.placeholder'
                            )}
                          />
                        </label>
                      )}
                    </Field>

                    <Field name="email" type="email">
                      {({ field, form: { touched, errors }, meta }) => (
                        <label>
                          {t('finished.candidateForm.email.title')}
                          <input
                            {...field}
                            type="email"
                            name="email"
                            placeholder={t(
                              'finished.candidateForm.email.title'
                            )}
                            required
                          />
                        </label>
                      )}
                    </Field>

                    <Field name="notes" type="textarea">
                      {({ field, form: { touched, errors }, meta }) => (
                        <label>
                          {t('finished.candidateForm.notes.title')}
                          <textarea
                            {...field}
                            type="notes"
                            name="notes"
                            rows="5"
                            placeholder={t(
                              'finished.candidateForm.notes.placeholder'
                            )}></textarea>
                        </label>
                      )}
                    </Field>

                    <button
                      type="submit"
                      className="button"
                      disabled={isSubmitting}>
                      {t('finished.candidateForm.submit')}
                    </button>
                  </form>
                )}
              </Formik>
            </fieldset>
          </div>
        </div>
        <div className={!collected ? 'hidden info-message' : 'info-message'}>
          {t('finished.candidateForm.collectedMessage')}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ testrunner }) => ({
  loading: testrunner.loading,
  collected: testrunner.collected,
  trun: testrunner.trun,
  data: testrunner.data
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      collectCandidateInfo
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CandidateForm))
