import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { Suspense } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './containers/app'
import Spinner from './containers/spinner'
import i18next from './i18n'
import { I18nextProvider } from 'react-i18next'

import 'sanitize.css/sanitize.css'
import 'react-toastify/dist/ReactToastify.css'
// import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'

const outdatedBrowserRework = require('outdated-browser-rework')
outdatedBrowserRework({
  browserSupport: {
    Chrome: 75, // Includes Chrome for mobile devices
    Edge: 44,
    Safari: 10,
    'Mobile Safari': 10,
    Firefox: 50,
    Opera: 50,
    Vivaldi: 1,
    Yandex: false,
    IE: false
  },
  requireChromeOnAndroid: true,
  isUnknownBrowserOK: false
})

const target = document.querySelector('#root')

render(
  <Suspense fallback={<Spinner />}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </ConnectedRouter>
    </Provider>
  </Suspense>,
  target
)
