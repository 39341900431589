/*
 * This file is part of Testfish Runner.
 * (c) Basis Europe <eu@basis.com>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import Config from "./config.js";
import axios from 'axios';

const instance = axios.create({
  baseURL: Config.apiBaseURL,
  responseType: "json",
  responseEncoding: "utf8",
  headers: {
    accept: "application/json"
  }
});

export default instance;
