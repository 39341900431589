import { createStore, applyMiddleware, compose } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { save, load } from 'redux-localstorage-simple'
import thunk from 'redux-thunk'
import * as History from 'history'
import rootReducer from './modules'

export const history = History.createBrowserHistory()

const localStorageConfig = { namespace: "testfish.runner" ,  states: ["testrunner"]};
const initialState = load(localStorageConfig)
const enhancers = []
const middleware = [thunk, routerMiddleware(history) , save(localStorageConfig) ]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

export default createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers
)
