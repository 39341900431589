import React from 'react'
import { Route } from 'react-router-dom'
import Home from '../home'
import Pub from '../pub'
import NotFound from '../notfound'
import { withTranslation } from 'react-i18next'

const App = () => (
  <main>
    <Route exact path="/" component={Home} />
    <Route exact path="/404" component={NotFound} />
    <Route exact path="/pub" component={Pub} />
  </main>
)

export default withTranslation()(App)
