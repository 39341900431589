import React from 'react'
import { withTranslation } from 'react-i18next';

const NotFound = ({ t }) => (
  <div className="notfound page">
    <div className="notfound__content">
      <h2>{t('notfound.title')}</h2>
      <p>{t('notfound.message')}</p>
      
      <img src="images/404.svg" alt="not-found" />
    </div>
  </div>
)

export default withTranslation()(NotFound)
