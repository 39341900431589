import React from 'react'
import CandidateForm from './candidateForm'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

class Finished extends React.Component {
  constructor(props) {
    super(props)
    window.onbeforeunload = null
  }

  componentDidMount() {
    const { data } = this.props
    if (data.redirect) {
      setTimeout(() => {
        window.location = data.redirect
      }, 2000)
    }
  }

  render() {
    const { data, results, t } = this.props
    return (
      <React.Fragment>
        {data.redirect ? (
          <div className="page">
            <p className="page__content">{t('finished.redirect')}</p>
          </div>
        ) : (
          <React.Fragment>
            <div className="page">
              <p
                className="page__content"
                dangerouslySetInnerHTML={{
                  __html: data.finishedtext
                }}></p>
              <p
                className="page__content"
                dangerouslySetInnerHTML={{
                  __html: results
                }}></p>
            </div>

            {data.collectcandidateinfo && (
              <div className="page page--form">
                <CandidateForm></CandidateForm>
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ testrunner }) => ({
  data: testrunner.data,
  results: testrunner.results
})

export default connect(mapStateToProps)(withTranslation()(Finished))
