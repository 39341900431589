import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createTestFromPub } from '../../modules/testrunner'
import Spinner from '../spinner'
import { withTranslation } from 'react-i18next'

class Pub extends React.Component {
  componentDidMount() {
    this.props
      .createTestFromPub()
      .then(() => {
        this.props.changePage()
      })
      .catch(e => {
        this.props.changePage('/404')
      })
  }

  render() {
    const { t } = this.props
    return <Spinner text={t('pub.loading')} />
  }
}

const mapStateToProps = ({ testrunner }) => ({
  loading: testrunner.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createTestFromPub,
      changePage: (page = '/') => push(page)
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Pub))
