import React from 'react'
import { withTranslation } from 'react-i18next';

class Welcome extends React.Component {
  constructor(props) {
    super(props)
    this.onUserStartedTest = this.onUserStartedTest.bind(this)
  }

  onUserStartedTest() {
    this.props.onUserStartedTest()
  }

  render() {
    const { t , data} = this.props;
    return (
      <div className="page">
        <img className="page__logo" src={data.logo} alt="logo" />
        <p
          className="page__content"
          dangerouslySetInnerHTML={{
            __html: data.welcometext
          }}></p>

        <button className="button" onClick={this.onUserStartedTest}>
            {data.numquestions === data.questionsleft &&  t('welcome.start')}
            {data.numquestions !== data.questionsleft &&  <React.Fragment>
              {t('welcome.continue')} {data.questionsleft} / {data.numquestions}
            </React.Fragment>}
        </button>
      </div>
    )
  }
}

export default withTranslation()(Welcome)